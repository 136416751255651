import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Col, Container, Row } from 'react-bootstrap'
import { useInView } from 'react-intersection-observer';
import { ContactButton } from '../../../Styled/CustomElements'
import { Box, HeadingStyled, TextStyled } from '../../../Styled/Elements'
import { AboutSocial, WhatsappBtn } from '../../../Styled/AllImages'


const AboutCard = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
  }, [inView]);
  const { t } = useTranslation()
  const WhatsApp = () => {
    const phoneNumber = '+37455724095';
    const message = 'Hello, Quantum Digtal';
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappURL, '_blank');
};
  return (
    <AboutCardWapper className='container my-5'>
      <Container>
        <Row className='card'>
          <Col>
            <Box className='card-content-wrap'>
              <HeadingStyled className='heading-text-response' color='var(--white)'>{t('Infusing_Social_Brilliance')}</HeadingStyled>
              <HeadingStyled className='sub-heading-text-response' size='24px' color='var(--white)'>{t('Ready_boost')}</HeadingStyled>
              <TextStyled color='var(--white)'>{t('Contact_today')}</TextStyled>
              <ContactButton className='card-btn' icon={WhatsappBtn} label={t('Enguire_Now')} onClick={WhatsApp} />
              <Box className={`rocket-animation ${isVisible ? 'animation' : ''}`} ref={ref}>
                <span className='about-card-img '>
                  <img className='' src={AboutSocial} alt='img' />
                </span>
              </Box>
            </Box>
          </Col>
        </Row>
      </Container>
    </AboutCardWapper>
  )
}
const AboutCardWapper = styled.section`
.card{
    position: relative;
}
.rocket-animation{
    position: absolute;
    width: 33%;
    right: 110px;
    top: -83px;
}

.about-card-img {
    position: relative;
    display: block;
    width:100%;
    height:100%;
}

`

export default AboutCard
