import React, { useEffect, useState } from 'react'
import { Box, LazyImage } from '../../Styled/Elements'
import { ArabicFooterLogo, ArabicLogo, HeadPhone, Logo } from '../../Styled/AllImages'
import { Container, Row } from 'react-bootstrap'
import { ButtonWithIcon } from '../../Styled/CustomElements'
import { NavDataConfig } from '../../Components/sliderData/Data'
import { Link } from 'react-scroll'
import { useTranslation } from 'react-i18next'
import { BsJustify, BsX } from "react-icons/bs"
import { useNavigate } from 'react-router-dom'
import { HeaderContainer } from './Styled'

const Header = () => {
    const navigate = useNavigate()
    const WhatsApp = () => {
        const phoneNumber = '+37455724095';
        const message = 'Hello, Quantum Digtal';
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };
    const { t } = useTranslation();
    const [isSticky, setIsSticky] = useState(false);
    const [toggle, setToggle] = useState(false);

    const handleToggle = () => {
        setToggle(!toggle);
        const element = document.querySelector(".nav-bar-list");
        element.classList.toggle("showToggle");
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 1) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <>
            <HeaderContainer className={`${isSticky ? 'sticky' : ''} container-fluid py-3`}>
                <Container>
                    <Row>
                        <Box className='nav-bar'>
                            <Box className='logo' onClick={() => navigate('/')}>
                                < Logo />
                            </Box>
                            <Box className='arabic-logo' onClick={() => navigate('/')}>
                                <LazyImage src={ArabicFooterLogo} />
                            </Box>
                            <Box className='nav-bar-list'>
                                {NavDataConfig.map((item, key) => (
                                    <Box key={key}>
                                        <Link to={item.to} spy={true} smooth={true} offset={-40} duration={500} activeClass="active" >{t(item.label)} </Link>
                                    </Box>
                                ))}
                            </Box>
                            <Box className='d-flex align-items-center gap-2 nav-btn'>
                                <ButtonWithIcon icon={HeadPhone} label='+966 963852741' onClick={WhatsApp} />
                                <span className="toggleBtn" onClick={handleToggle}>
                                    {toggle ? <BsX size={30} /> : <BsJustify size={30} />}
                                </span>
                            </Box>
                        </Box>
                    </Row>
                </Container>
            </HeaderContainer >
        </>
    )
}


export default Header
